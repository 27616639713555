.wrapper {
  color: #454e57;
  background: #f9f9fa;
  padding: 2rem 6rem;
  overflow-wrap: break-word;

  & a {
    color: #1975ff;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
}

.navigation {
  width: 20rem;
  position: sticky;
  top: 10rem;
  padding: 1.5rem;
  background: #ffffff;
  border: 1px solid #f3f5f7;
  border-radius: 12px;

  & > ul {
    list-style: none;
    padding: 0;
  }
  & li {
    line-height: 22px;
    & > a:hover {
      text-decoration: underline;
    }
  }
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 52px;
  & a {
    color: #1975ff;
    text-decoration: none;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 2rem;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.content {
  & h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  :global(.richTextTableWrapper) {
    overflow-x: auto;
  }

  & table {
    border-collapse: collapse;
    border-radius: 20px;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }

    & th {
      background: #f3f5f7;
      text-transform: uppercase;
    }

    & th,
    & td {
      padding: 20px;
      @media screen and (max-width: 768px) {
        padding: 5px;
      }
    }

    & td {
      vertical-align: top;
    }

    & td:not(:last-child),
    & th:not(:last-child) {
      border-right: 1px solid #d4dae0;
    }

    tr:first-child td:first-child,
    tr:first-child th:first-child {
      border-top-left-radius: 20px;
    }

    tr:first-child td:last-child,
    tr:first-child th:last-child {
      border-top-right-radius: 20px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    & > li {
      background: url('assets/list-arrow.svg') no-repeat 0 2px;
      padding-left: 2rem;
      & ul {
        list-style-type: disc;
        & > li {
          background: none;
          padding-left: 1rem;
        }
      }
    }
  }

  details {
    & summary {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #454e57;
      list-style: none;
      width: max-content;
      padding-right: 2rem;
      background: url('assets/toggle.svg') no-repeat right center;
      cursor: pointer;

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] {
      & summary {
      }
    }
  }
}
